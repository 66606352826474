















































































import { Prop, Component, Vue, Watch } from 'vue-property-decorator'
//@ts-ignore
import _ from 'lodash'

@Component
export default class ModalChangeOrder extends Vue {
  @Prop({ default: 'change-order' }) private id!: string
  @Prop() private listOrder!: any[]
  @Prop() private displayNameKey!: string
  @Prop() private selected!: string
  private cloneList: any[] = []
  private selectedItem: number = 0
  private ableClose: boolean = false

  handlePreventEvent(event: any) {
    if (!this.ableClose && !_.isEqual(this.listOrder, this.cloneList)) {
      event.preventDefault()
      this.confirmHideModal()
    }
  }

  created() {
    this.updateList()
  }

  @Watch('listOrder')
  updateList() {
    this.cloneList = [...this.listOrder]
  }

  @Watch('selected')
  watchSelected() {
    this.selected
      ? (this.selectedItem = this.cloneList.findIndex(
          item => item[this.displayNameKey] === this.selected
        ))
      : (this.selectedItem = 0)
  }

  confirmHideModal() {
    if (_.isEqual(this.listOrder, this.cloneList)) {
      this.hideModal()
    } else {
      this.$bvModal.show('change-order-modal-confirm')
    }
  }

  async hideModal() {
    await this.handleHide()
    this.selected
      ? (this.selectedItem = this.cloneList.findIndex(
          item => item[this.displayNameKey] === this.selected
        ))
      : (this.selectedItem = 0)
    this.ableClose = false
  }

  async handleHide() {
    this.ableClose = true
    this.$bvModal.hide(this.id)
  }

  handleChangeOrder(to: number) {
    if (to < 0 || to === this.cloneList.length) {
      return
    }
    const element = this.cloneList[this.selectedItem]
    this.cloneList.splice(this.selectedItem, 1)
    this.cloneList.splice(to, 0, element)
    this.selectedItem = to
  }

  onSubmit() {
    this.$emit('submit', this.cloneList)
    this.hideModal()
  }
}
